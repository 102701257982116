/* eslint-disable complexity */
import React, {useContext, useState} from "react"
import {Form, Formik} from "formik"
import {formNewsletterHandler} from "../../utility/postHandler"
import {useTranslation} from "next-i18next"
import * as Yup from "yup"
import FormInput, {Color} from "../reusable/FormInput/FormInput"
import {useRouter} from "next/router"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import Button from "../reusable/components/Button"
import Typography from "../reusable/Typography/Typography"
import LayoutContext from "../../context/LayoutContext"
import CheckBoxField from "./fields/CheckBoxField"
import AnalyticsContext from "../../context/AnalyticsContext"
import Link from "next/link"
import Image from "next/image"

interface MyFormValues {
  firstname: string,
  lastname: string,
  email: string,
  checkboxAgreement: boolean
}

type field = {
  label: string
  name: string
}

type Props = {
  fields: field [],
  blogNewsletter?: boolean,
  fieldBgColor?: Color.dark | Color.light | Color.white,
  discountForNewsletter?: boolean,
  noFiltersResults?: boolean // For form on PLP when zero filters results, change
  footer?: boolean
  buttonText?: string
	inputClassName?: string
}

enum PathNameValue {
  blog = "blog"
}

const FormNewsletter = ({
	fields,
	blogNewsletter = false,
	fieldBgColor = Color.dark,
	discountForNewsletter = false,
	noFiltersResults = false,
	footer = false,
	buttonText = "",
	inputClassName
}: Props) => {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [defaultStatus, setDefaultStatus] = useState(200)
	const [isLoading, setIsLoading] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)

	const router = useRouter()
	const {locale, pathname} = router
	const instanceId = locale === "de" ? process.env.NEXT_PUBLIC_FORM_NEWSLETTER_DE : locale === "fr" ? process.env.NEXT_PUBLIC_FORM_NEWSLETTER_FR : process.env.NEXT_PUBLIC_FORM_NEWSLETTER_DE

	const isBlogPage = pathname.includes(PathNameValue.blog)
	const initialValues: MyFormValues = {
		firstname: "",
		lastname: "",
		email: "",
		checkboxAgreement: false
	}
	const schema = Yup.object().shape({
		firstname: Yup.string()
			.min(2, t("Too Short!"))
			.max(50, t("Too Long!"))
			.required(t("required")),
		lastname: Yup.string()
			.min(2, t("Too Short!"))
			.max(50, t("Too Long!"))
			.required(t("required")),
		email: Yup.string()
			.email(t("Invalid email"))
			.required(t("required")),
		checkboxAgreement: Yup.boolean()
			.oneOf([true])
			.required(t("required"))
	})

	return (
		<>
			{formSubmitted ?

				<p
					className={`col-12 ${blogNewsletter ? "text-dark mt-4" : "text-white col-lg-5 mt-5 mb-5 pt-5"}`}>{t("Vielen Dank für deine Anmeldung beim Newsletter! Neuigkeiten und spezielle Angebote werden nun direkt in dein Postfach gesendet.")}{" "}{"😊📮"}</p> :

				<Formik
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						const obj = {
							formId: process.env.NEXT_PUBLIC_FORM_ID,
							instanceId,
							data: values
						}

						const response = await formNewsletterHandler(obj, setIsLoading)
						// Log event
						reactGA?.event({
							category: gaCategories.newsletter,
							action: isBlogPage ? gaEvents.newsletterFormSubmittedBlog : gaEvents.newsletterFormSubmitted,
							label: isBlogPage ? gaEvents.newsletterFormSubmittedBlog : gaEvents.newsletterFormSubmitted,
							nonInteraction: true
						})

						if (response.status === 200) {
							setFormSubmitted(true)
						}

						if (response.status !== 200) {
							setDefaultStatus(response.status)
						}

						actions.setSubmitting(false)
					}}
				>
					{({errors, touched}) => (
						<Form className={`col-12 ${blogNewsletter ? "" : "col-lg-5 mt-md-5 mb-5 pt-md-5 mt-4"}`}>
							{footer && <Typography style={{fontSize: "20px"}} className="mb-4 d-none d-md-flex" variant="heading3">{t("footer-newsletter-title")}</Typography>}
							{defaultStatus !== 200 && <div className="alert alert-danger mb-4" role="alert">
								{t("Beim Absenden des Formulars ist etwas schiefgelaufen.")}
							</div>}
							{/* {!discountForNewsletter && !blogNewsletter && <Typography className="col-12 mb-3" variant={`${isMobile ? "heading2" : "heading3"}`} semanticTag="h2">{t("JETZT SPAREN")}</Typography>} */}
							{!discountForNewsletter &&
              (!isMobile && !footer) && fields.map(({name, label}) => (
								<FormInput key={name} errors={errors} label={t(label)} touched={touched} name={name}
									bgColor={fieldBgColor} color={blogNewsletter ? Color.dark : Color.white}/>))}
							{!discountForNewsletter && isMobile && (
								<div>
									{/* <Typography className="col-12 mb-3" variant={`${isMobile ? "heading2" : "heading3"}`} semanticTag="h2">{t("JETZT SPAREN")}</Typography> */}
									<div className="d-flex justify-content-between">
										<div style={{width: "46%"}}>
											<FormInput
												errors={errors}
												label={t("Vorname")}
												touched={touched}
												name={"firstname"}
												bgColor={fieldBgColor}
												color={blogNewsletter ? Color.dark : Color.white}
											/>
										</div>
										<div style={{width: "46%"}}>
											<FormInput
												errors={errors}
												label={t("Nachname")}
												touched={touched}
												name={"lastname"}
												bgColor={fieldBgColor}
												color={blogNewsletter ? Color.dark : Color.white}
											/>
										</div>
									</div>
									<div className="mt-2">
										<FormInput
											errors={errors}
											label={t("email")}
											touched={touched}
											name={"email"}
											bgColor={fieldBgColor}
											color={blogNewsletter ? Color.dark : Color.white}
										/>
									</div>
								</div>
							)}

							{(discountForNewsletter || (footer && !isMobile)) && (
								<div>
									{/* <Typography className="col-12 mb-3" variant={`${isMobile ? "heading2" : "heading3"}`} semanticTag="h2">{t("JETZT SPAREN")}</Typography> */}
									<div className="d-flex justify-content-between">
										<div style={{width: "48%"}}>
											<FormInput
												errors={errors}
												label={t("Vorname")}
												touched={touched}
												name={"firstname"}
												color={blogNewsletter ? Color.dark : Color.white}
											/>
										</div>
										<div style={{width: "48%"}}>
											<FormInput
												errors={errors}
												label={t("Nachname")}
												touched={touched}
												name={"lastname"}
												bgColor={fieldBgColor}
												color={blogNewsletter ? Color.dark : Color.white}
											/>
										</div>
									</div>
									<div>
										<FormInput
											errors={errors}
											label={t("email")}
											touched={touched}
											name={"email"}
											bgColor={fieldBgColor}
											color={blogNewsletter ? Color.dark : Color.white}
											className={inputClassName}
										/>
									</div>
								</div>
							)}

							{/* {!isMobile && ( */}
							<div className="col-12 d-flex flex-row flex-start">
								<CheckBoxField name={"checkboxAgreement"}>
									<Typography
										className=""
										variant="bodySm"
										semanticTag="span"
									>
										Mit der Auswahl dieser Bedingung akzeptiere ich Informationen im Rahmen des MySofaPlan-Newsletters zu erhalten.
									</Typography>
								</CheckBoxField>
							</div>
							{/* )} */}

							{errors &&
              errors.checkboxAgreement &&
              touched.checkboxAgreement ? (
									<Typography
										variant="bodyLg"
										semanticTag="div"
										className={"text-danger mb-3"}
									>
										{t("required")}
									</Typography>
								) : null}

							{noFiltersResults ? (
								<div className="row">
									<div className="col-12 col-lg-auto">
										<Button
											className="w-100"
											outline
											label={buttonText ? t(buttonText) : t("Absenden")}
											color={"black"}
											type={"submit"}
											isLoading={isLoading}
											spinnerColor={"white"}
										/>
									</div>
								</div>
							) : footer ? (
								<div className="row">
									<div className={`${isMobile ? "col-12" : "col-auto"}`}>
										<Button
											fontSizeSmall={!isMobile}
											label={buttonText ? t(buttonText) : t("Absenden")}
											type={"submit"}
											isLoading={isLoading}
											textColor={"white"}
											hoverColor="#fff"
											spinnerColor={"white"}
											outline
											paddingY="2"
											color={isMobile ? "#212529" : "black"}
											className={`w-100 ${!isMobile && "border"}`}
										/>
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col-12 col-lg-auto mt-3 pt-4">
										<Button
											className="w-100"
											label={buttonText ? t(buttonText) : t("Absenden")}
											textColor="#fff"
											bgColor="#212529"
											type={"submit"}
											isLoading={isLoading}
											spinnerColor={"white"}
										/>
									</div>
								</div>
							)}

							{footer && !isMobile &&
								<div className="footer-item mt-5">
									<Typography variant={"bodyLgBlack"} className="mb-3" style={{fontSize: 20}}>Follow us on</Typography>
									<div className="row g-3">
										<div className="col-auto">
											<Link href={"https://www.instagram.com/myhomeplan.ch/"}>
												<a target="_blank" className="text-md text-white mt-5">
													<Image src={"/assets/icons/ig-white-icon.svg"} width={24} height={24}/>
												</a>
											</Link>
										</div>
										<div className="col-auto ms-2">
											<Link href={"https://www.facebook.com/profile.php?id=100095802481729"}>
												<a target="_blank" className="text-md text-white mt-5">
													<Image src={"/assets/icons/fb-white-icon.svg"} width={24} height={24}/>
												</a>
											</Link>
										</div>
									</div>
								</div>
							}
						</Form>
					)}
				</Formik>
			}

		</>
	)
}

export default FormNewsletter

FormNewsletter.defaultProps = {
	fields: [
		{
			label: "Vorname",
			name: "firstname"
		},
		{
			label: "Nachname",
			name: "lastname"
		},
		{
			label: "email",
			name: "email"
		}
	]
}
